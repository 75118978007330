import MessageMap from '../i18n.d';

const SKU_PRIOTIRY_FROM_TO = 'Priority {from} to {to}';

const es419: MessageMap = {
  test: {
    translate: 'Traducción',
    missing: 'Only English option available'
  },
  // NEED COPYDECK FOR DYNAMIC VALIDATIONERRORS
  DynamicComponents: {
    ValidationErrors: {
      Required: 'Campo obligatorio.',
      IsChecked: 'This field should be checked.',
      MinLength: 'Debe tener al menos {value} caracteres.',
      MinValue: 'Debe ser un valor mayor que {value}.',
      MaxValue: 'Debe ser un valor menor que {value}.',
      MaxValueBasedOnField: 'Must be a value smaller than {value} ("{field}" field).',
      MinValueBasedOnField: 'El {primary_field} debe ser mayor o igual que la {field}.',
      AlphaNumeric: 'The value must be alphanumeric. Only letters, numbers and dash are allowed.',
      MustBeGreaterThanZero: 'Debe ser mayor a 0.'
    },
    OptionalLabel: '(opcional)',
    Components: {
      budgetId: {
        Label: 'ID de presupuesto',
        Placeholder: 'Ingresa el código de ID del presupuesto'
      },
      title: {
        Label: 'Título',
        Subtitle: 'Este título se mostrará a los clientes.',
        Placeholder: 'Ingresar título'
      },
      titleInput: {
        Label: 'Title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      startDateAndEndDate: {
        Label: 'Rango de fechas del punto de contacto',
        Placeholder: 'Ingresar período'
      },
      description: {
        Label: 'Descripción',
        Subtitle: 'Esta descripción se mostrará a los clientes.',
        Placeholder: 'Ingresar descripción'
      },
      allocation: {
        Label: 'Asignación',
        Tooltip:
          'El porcentaje de PDV más recomendados dentro de la audiencia seleccionada para recibir el descuento.',
        Placeholder: 'Ingresa el porcentaje de asignación'
      },
      skuTable: {
        Label: 'SKU Table',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      pointsValue: {
        Label: 'Puntos',
        Placeholder: 'Ingresar la cantidad de puntos'
      },
      rewardsCampaignId: {
        Label: 'Id. de grupo de campaña',
        Placeholder: 'Ingresar Id. de grupo de campaña',
        Tooltip:
          'Utiliza este campo para asociar este punto de contacto con cualquier otra campaña que puedas estar ejecutando en otras plataformas.'
      },
      eligibleOrderMaximum: {
        Label: 'Máximo de pedidos por PDV',
        Tooltip:
          'La cantidad máxima de pedidos elegibles que cada PDV puede realizar durante la promoción.',
        Placeholder: 'Ingresa un número'
      },
      skuLimit: {
        Label: 'Máximo de SKUs por PDV',
        Tooltip: 'La cantidad máxima de cada SKU que un PDV puede comprar.',
        Placeholder: 'Ingresa un número'
      },
      discountAndQuantities: {
        errors: {
          minQtyMessageError: 'La cantidad mínima debe ser mayor o igual que 1.',
          maxQtyMessageError: 'La cantidad máxima debe ser mayor o igual que la cantidad mínima.',
          discountMessageError: 'El descuento debe ser igual o mayor que 1.',
          minDiscountMessageError: 'El descuento mínimo debe ser igual o mayor que 1.',
          maxDiscountMessageError:
            'El descuento máximo debe ser mayor o igual al descuento mínimo.',
          discountTieredMessageError: 'El descuento debe ser mayor o igual que el nivel anterior.'
        },
        type: {
          nonPersonalized: {
            Label: 'Descuentos y cantidades',
            Subtitle: 'Los siguientes valores se aplicarán a todos los SKU seleccionados.'
          },
          personalized: {
            Label: 'Descuentos y cantidades',
            Subtitle:
              'Completa los campos a continuación a fin de que nuestro algoritmo pueda recomendar los mejores descuentos para cada PDV.'
          },
          tieredNonPersonalized: {
            Label: 'Descuentos y cantidades',
            Subtitle: 'Los siguientes valores se aplicarán a todos los SKU seleccionados.'
          },
          tieredPersonalized: {
            Label: 'Descuentos y cantidades',
            Subtitle:
              'Completa al menos 2 niveles a continuación a fin de que nuestro algoritmo pueda recomendar los mejores descuentos para cada PDV.'
          }
        },
        minQuantity: {
          Label: 'Cantidad mín. por pedido',
          Placeholder: 'Ingresa un número'
        },
        maxQuantity: {
          Label: 'Cantidad máx. por pedido',
          Placeholder: 'Ingresa un número'
        },
        minDiscount: {
          Label: 'Descuento mín.',
          Placeholder: 'Ingresa el porcentaje de descuento'
        },
        maxDiscount: {
          Label: 'Descuento máx.',
          Placeholder: 'Ingresa el porcentaje de descuento'
        },
        fixDiscount: {
          Label: 'Descuento',
          Placeholder: 'Ingresa el porcentaje de descuento'
        },
        // This is just a tierTable copydeck example, you can change the name of the fields to match your needs
        tierTable: {
          AddTierButton: 'Agregar nivel',
          Title: 'Nivel',
          MinQuantity: 'Cantidad mín.',
          MaxQuantity: 'Cantidad máx.',
          Discount: 'Descuento %',
          // missing copydeck Actions
          Actions: 'Eliminar'
        }
      },
      Completo: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      testingNumber: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMaxField: {
        Label: 'Max input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMinField: {
        Label: 'Min input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      TEST: {
        Label: 'Test input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      bannerTitle: {
        Label: 'Banner title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      numberOfUnits: {
        Label: 'Unidades',
        Subtitle: 'La cantidad de unidades requerida para completar la oferta.',
        Placeholder: 'Ingresar el número de unidades'
      },
      dateField: {
        Label: 'Date Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      checkboxField: {
        Label: 'Checkbox Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      }
    }
  },
  Components: {
    DatePicker: {
      Label: 'Rango de fechas del punto de contacto',
      Placeholder: 'Ingresar período',
      Error: 'Ingresa un nuevo período.'
    },
    SkuLimit: {
      Label: 'Máximo de SKUs por PDV',
      Tooltip: 'La cantidad máxima de cada SKU que un PDV puede comprar.',
      Placeholder: 'Ingresa un número'
    }
  },
  Breadcrumb: {
    CampaignList: 'Lista de ofertas',
    NewCampaign: 'Crear oferta',
    CampaignDetails: 'Detalles de la oferta'
  },
  NewCampaign: {
    Heading: 'Crear nueva campaña',
    BreadcrumbLabel: 'Crear nueva campaña',
    SubHeading: 'Estrategia',
    Info: 'Seleccione una estrategia y subestrategia. Esta información potenciará la eficiencia de su campaña.',
    Cancel: 'Cancelar',
    Create: 'Crear',
    CampaignsTitle: 'Campaña',
    CampaignPageTitle: 'Lista de ofertas'
  },
  countryChangeConfirmationModal: {
    Title: '¿Cambiar país?',
    Description: 'Si cambias de país, se perderán los cambios que no hayan sido guardados.',
    Cancel: 'No, volver',
    Confirm: 'Sí, cambiar'
  },
  vendorChangeConfirmationModal: {
    Title: '¿Cambiar proveedor?',
    Description: 'Si cambias de proveedor, se perderán los cambios que no hayan sido guardados.',
    Cancel: 'No, volver',
    Confirm: 'Sí, cambiar'
  },
  sellerChangeConfirmationModal: {
    Title: '¿Cambiar vendedor?',
    Description: 'Si cambias de vendedor, se perderán los cambios que no hayan sido guardados.',
    Cancel: 'No, volver',
    Confirm: 'Sí, cambiar'
  },
  HomePage: {
    Levers: {
      Title: 'Lista de ofertas',
      Table: {
        Name: 'Nombre de la oferta',
        Category: 'Categoría',
        Touchpoint: 'Touchpoint types',
        CampaignId: 'ID de oferta',
        Period: 'Período',
        Status: 'Estado',
        Deactivate: 'Desactivar',
        edit: 'Editar',
        Actions: 'Acciones',
        Challenge: 'Desafío',
        Promotion: 'Promoción',
        DigitalCommunication: 'Comunicación digital',
        PageSizeOptions: 'Artículos por página: {options}',
        QuantityIndicator: 'de'
      }
    }
  },
  StrategyCard: {
    Strategies: {
      INTRODUCE: 'Presentar',
      SUSTAIN: 'Mantener',
      GENERATE_DEMAND: 'Generar demanda',
      OPERATIONS: 'Operaciones'
    },
    StrategyDescription: {
      INTRODUCE: 'Aumentar la cobertura de productos nuevos o existentes',
      SUSTAIN: 'Alentar la repetición de compras y aumentar la frecuencia de compra.',
      GENERATE_DEMAND: 'Aumentar las compras estratégicamente.',
      OPERATIONS:
        'Impulsar las compras para gestionar las operaciones diarias y cumplir con los objetivos de rutina.'
    },
    SelectLabel: 'Seleccionar subestrategia',
    SubStrategies: {
      I1: 'I1: Atraer a los clientes para que prueben nuevos productos',
      I2: 'I2: Mejorar la cobertura de los productos existentes',
      I3: 'I3: Oferta de bienvenida B2B',
      S1: 'S1: Alentar a los clientes a repetir las compras',
      G1: 'G1: Impulsar el tráfico peatonal a través de eventos del consumidor = promoción',
      G2: 'G2: Alentar a los PDV a mantener el cumplimiento diario de los precios',
      G3: 'G3: Aumentar el consumo al pasar un precio más bajo a los consumidores',
      G4: 'G4: Optimizar el surtido en los PDV',
      G5: 'G5: Negociar la ubicación de características, exhibidores, estantes o planogramas',
      G6: 'G6: Programa comercial: descuento por logro objetivo',
      G7: 'G7: Generar demanda de SKU de baja venta o cercanas a la expiración para reducir las pérdidas de la cadena de suministro y la demanda de SKU de baja venta o cercanas a la expiración',
      O1: 'O1: Aprovechar las promociones para alcanzar el objetivo mensual/trimestral',
      O2: 'O2: Responder a la dinámica del mercado',
      O3: 'O3: Descuento relacionado con el pago (p. ej., descuento en efectivo)',
      O4: 'O4: Reducir la carga de distribución (p. ej., picos de demanda previstos según la ocasión prevista, distribución a almacenes de KA, etc.) basado en la ocasión',
      O5: 'O5: Generar demanda en ventas bajas o reducir las pérdidas de la cadena de suministro',
      O6: 'O6: Otros (descuento para empleados, recompensas, etc.)'
    }
  },
  chipCopywrite: {
    ddc: '{value}',
    'ddc-uc': '{value}',
    segment: '{value}',
    channel: '{value}',
    'sales-uen': '{value}',
    'sales-state': '{value}',
    'sales-region-drv': '{value}',
    'sales-region-geo': '{value}',
    'digital-order-since-comparison': '{operator} {value, plural, one {# día} other {# días}}',
    'digital-order-since': 'Last {value, plural, one {# día} other {# días}}',
    'engagement-score': '{value}',
    'orders-count': '{operator} {value}',
    'purchased-marketplace-count': '{operator} {value}',
    'purchased-sku-count': '{operator} {operatorValue}: {value}',
    'sku-priority-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-introduce-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-sustain-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-generate-range-id': SKU_PRIOTIRY_FROM_TO,
    'service-model': '{model}: {vendor}',
    label: {
      ddc: 'Comprado en DDC',
      'ddc-uc': 'Comprado en DDC',
      segment: 'Segment',
      channel: 'Canal',
      'sales-uen': 'UEN',
      'sales-state': 'Estado',
      'sales-region-drv': 'Región de ventas',
      'sales-region-geo': 'Región de ventas',
      'digital-order-since-comparison': 'Último pedido en',
      'digital-order-since': 'Último pedido en',
      'engagement-score': 'Calificación de compromiso',
      'orders-count': 'Ordenó una cantidad',
      'purchased-marketplace-count': 'Purchased marketplace',
      'purchased-sku-count': 'SKU comprada',
      'sku-priority-range-id': 'Priorización de SKU',
      'sku-priority-introduce-range-id': 'Introduce',
      'sku-priority-sustain-range-id': 'Sustain',
      'sku-priority-generate-range-id': 'Generate',
      'service-model': 'Service model'
    }
  },
  operators: {
    greaterThan: 'Más de',
    lowerThan: 'Menos de',
    equals: 'Equivale a',
    yes: 'Sí',
    no: 'No'
  },
  Expressions: {
    High: 'Alto',
    Medium: 'Medio',
    Low: 'Bajo'
  },
  ModalDeleteOffer: {
    Title: '¿Desactivar oferta?',
    Body: 'Una vez desactivada, la oferta <offer_id> ya no se mostrará a los clientes.',
    ConfirmMessage: 'Sí, desactivar',
    CancelMessage: 'No, volver',
    SuccessToast: 'La oferta {offerId} será desactivada. Esto puede tardar un poco.',
    FailedToast: `No fue posible desactivar la oferta {offerId}. Inténtalo de nuevo.`
  },
  SettingsPage: {
    Title: 'Configuración',
    Description: 'Agrega nombre, período y SKU.',
    DetailsCard: {
      Title: 'Configuración',
      Name: 'Nombre de la oferta',
      Error: 'El nombre debe tener al menos 3 caracteres.',
      ErrorNameUsed: 'El nombre de la campaña ya está en uso. Prueba con otro.',
      Period: {
        Label: 'Período',
        Placeholder: 'Ingresar período',
        Error: 'Ingresa un nuevo período'
      }
    },
    StrategyComponent: {
      Strategy: 'Estrategia',
      Substrategy: 'Subestrategia'
    },
    EmptySku: {
      Message:
        'Aún no se han seleccionado SKU. Este tipo de oferta requiere que agregues al menos una SKU para continuar.',
      Action: 'Seleccionar SKU'
    },
    EmptyState: {
      Message: 'No hay SKU para mostrar.'
    },
    ModalEditSku: {
      Title: '¿Editar rango de fechas de campaña?',
      Subtitle:
        'Esta acción restablecerá todas las fechas establecidas dentro de los puntos de contacto.',
      Action: 'Sí, editar',
      Cancel: 'No, cancelar'
    },
    Alert:
      'Han pasado las fechas de ejecución de esta campaña. Selecciona un nuevo período para publicar este borrador.'
  },
  AudiencePage: {
    Title: 'Audiencia',
    Description: 'Especifica quién se verá afectado.',
    warningText:
      'La audiencia inteligente está activada, por lo que la cantidad estimada de PDV objetivo podría cambiar. La nueva estimación con los PDV sugeridos para esta oferta se mostrará en la página Resumen.'
  },
  TouchpointsPage: {
    Touchpoints: 'Punto de contacto',
    Title: 'Puntos de contacto',
    Description: 'Selecciona un tipo de interacción.',
    TouchpointCategory: {
      RewardsChallenge: 'Rewards challenge',
      Promotion: 'Promotions'
    },
    Modal: {
      Title: 'Seleccionar punto de contacto',
      Search: 'Buscar por categoría o nombre de punto de contacto',
      Action: 'Seleccionar punto de contacto',
      Cancel: 'Volver',
      Results: 'results',
      NoResultsFound: 'No results found. Try changing your search',
      ClearSearch: 'Clear search'
    },
    EmptyStateCard: {
      Title: 'Aún no has seleccionado ningún punto de contacto',
      Subtitle: 'Los puntos de contacto que selecciones aparecerán aquí.',
      ButtonLabel: 'Seleccionar punto de contacto'
    },
    EditButton: 'Editar punto de contacto',
    editDialog: {
      Title: '¿Editar punto de contacto?',
      Subtitle:
        'Esto restablecerá todos los detalles que puedas haber agregado a este punto de contacto.',
      Action: 'Sí, editar',
      Cancel: 'No, volver'
    },
    RemoveModal: {
      Title: '¿Quitar punto de contacto?',
      Subtitle: 'Esto eliminará el punto de contacto y todos sus detalles.',
      Action: 'Sí, retirar',
      Cancel: 'No, volver'
    }
  },
  TouchpointDetailsPage: {
    TouchpointType: 'Pedido múltiple',
    Title: 'Detalles del punto de contacto',
    Card: {
      Title: 'Cantidad de SKU'
    },
    Description: 'Configura la información.',
    SelectedAudienceCard: {
      Title: 'Público seleccionado'
    },
    SkuCard: {
      AlertText:
        'Seleccionar una SKU única creará múltiples desafíos con los mismos puntos y contenido.',
      SingleSkuTitle: 'SKU única',
      SingleSkuDescription: 'El PDV recibe múltiples desafíos con 1 SKU cada uno.',
      MultipleSkuTitle: 'Múltiples SKU',
      MultipleSkuDescription:
        'El PDV recibe 1 desafío con todas las SKU seleccionadas a continuación.',
      ChallengeskuLabel: 'Pantalla de desafío'
    },
    SkuSelectionCard: {
      Title: 'SKU',
      Subtitle: 'Añade los SKU que estarán relacionados con esta oferta.',
      Product: 'Producto',
      Code: 'Código de SKU',
      Remove: 'Eliminar',
      AddSKU: 'Seleccionar SKU',
      AddBySearch: 'Añade por búsqueda',
      AddByCSV: 'Añade por archivo CSV',
      CSVUploadModal: {
        Title: 'Añade SKU por archivo CSV',
        SubTitle: 'Descarga la plantilla de archivo, complétala y cárgala.',
        DownloadButton: 'Descargar plantilla de archivo',
        DragAndDropTitle: 'Arrastra o busca tu archivo CSV',
        Limit: 'Limit: 300 rows/IDs.',
        DragAndDropSubtitle:
          'El nombre del archivo no debe contener espacios, guiones bajos ni caracteres especiales, excepto "-".',
        UploadButton: 'Cargar archivo',
        LimitError: 'El número de filas excede el límite permitido de 300.',
        FileNameError:
          'El nombre del archivo no debe contener espacios, guiones bajos ni caracteres especiales, excepto "-".',
        InvalidFormatError: 'Formato de archivo inválido. Suba un archivo en el formato permitido.',
        EmptyFileError: 'El archivo está vacío. Suba un archivo con contenido.',
        SkuIdHeaderError:
          'El archivo debe tener una columna de Código de SKU. Asegúrese de seguir las pautas de formato descritas en la plantilla a continuación.',
        ReadFileError: 'No se pudo leer el archivo. Intente de nuevo.',
        CorruptedFile: 'El archivo está corrupto. Suba un archivo válido.',
        CancelButton: 'Cancelar'
      }
    },
    ReviewSKUsWithErrorsAlert: {
      Message: 'There are {value} SKUs with errors. Review them and upload a new file.',
      Action: 'Review SKUs'
    },
    ModalReviewSKUsWithErrors: {
      Title: 'Review SKUs with errors',
      Subtitle: `These SKUs could be inactive or invalid. To fix errors, review them and upload a new file. SKUs with errors won't be included in the offer.`,
      Action: 'Close'
    },
    AudienceSelectedItem: {
      Trait: 'rasgo',
      Traits: 'rasgos',
      CSVFile: 'CSV file',
      TextButton: 'Mostrar todo',
      TraitBox: 'Rasgos'
    },

    DetailsCard: {
      Title: 'Detalles del punto de contacto',
      EmptyQuantity: {
        Message:
          'No se ha establecido ninguna cantidad.{br} Debes agregar una cantidad para cada SKU en el desafío.',
        Action: 'Establecer cantidad'
      },
      CoverImage: {
        message1: 'El archivo debe tener formato JPG o PNG',
        message2: 'y hasta 1 MB de tamaño.',
        error: 'El tamaño o formato del archivo no es válido. Intenta cargarlo de nuevo.',
        button: 'Seleccionar archivo',
        label: 'Imagen de portada'
      },
      Badge: {
        completed: 'Completado',
        pending: 'Pendiente'
      },
      ItemCard: {
        Category: 'Desafío de recompensas',
        TouchpointType: 'Pedido múltiple'
      },
      DatePicker: {
        Label: 'Rango de fechas del desafío'
      },
      InputTitle: {
        Label: 'Título',
        Placeholder: ''
      },
      InputDescription: {
        Label: 'Descripción',
        Placeholder: 'Ingresar una descripción'
      },
      InputPoints: {
        Label: 'Puntos',
        Placeholder: 'Ingresar la cantidad de puntos'
      },
      InputCampaignGroupId: {
        Label: 'Id. de grupo de campaña',
        Placeholder: '',
        Optional: '(opcional)',
        Tooltip:
          'Utiliza este campo para asociar este punto de contacto con cualquier otra campaña que puedas estar ejecutando en otras plataformas.'
      },
      InputUnits: {
        Label: 'Unidades',
        Hint: 'La cantidad de unidades requerida para completar la oferta.',
        Placeholder: 'Ingresar el número de unidades'
      },
      SKUQuantityTable: {
        Title: 'Cantidad de SKU',
        EditQuantity: 'Editar cantidad',
        Product: 'Producto',
        SKUCode: 'Código de SKU',
        Quantity: 'Cantidad',
        RewardsChallengeId: 'ID del desafío de recompensas'
      },
      ValidationErrors: {
        minLength: 'Debe tener al menos {value} caracteres.',
        minValue: 'Debe ser un valor mayor que {value}.'
      },
      SkuRemoveTable: {
        Title: 'SKU',
        AddSKU: 'Agregar SKU',
        EditSKU: 'Editar SKU',
        EmptyQuantity: {
          Message: 'No se han agregado SKU. Debes agregar al menos un SKU para continuar.'
        }
      }
    },
    SKUQuantity: {
      Dialog: {
        Title: 'Establecer cantidad',
        Cancel_Button: 'Cancelar',
        Apply_Button: 'Aplicar'
      }
    },
    MultipleOrder: {
      Title: 'Pedido múltiple',
      Description:
        'Un desafío que permite que los PDV ganen puntos al comprar SKU requeridas dentro de un determinado período.'
    },
    InteractiveCombo: {
      Conditions: {
        Title: 'Productos',
        Paragraph:
          'Agregue los productos y la cantidad que la PDV necesita pedir para obtener un descuento.',
        Selection: 'Selección',
        CreateSelection: 'Crear selección',
        EmptySelection: 'No se agregaron SKUs. Comience a agregar SKUs a esta selección.',
        MinQuantityField: 'Cantidad mínima requerida',
        Modals: {
          RemoveCondition: {
            Title: '¿Eliminar selección de SKU?',
            Description: 'También se borrarán los descuentos asociados a estos SKU.',
            ConfirmMessage: 'Eliminar selección',
            CancelMessage: 'Cancelar'
          },
          RemoveItem: {
            Title: '¿Eliminar SKU?',
            Description: 'El descuento asociado a este SKU también se borrará.',
            ConfirmMessage: 'Eliminar SKU',
            CancelMessage: 'Cancelar'
          }
        }
      }
    },
    InteractiveComboDiscount: {
      Title: 'Descuento',
      Description:
        'Establezca descuentos y limite la cantidad máxima de productos que se pueden comprar con un descuento.',
      SelectLabel: 'Tipo de descuento',
      PERCENT_OFF: 'Porcentaje de descuento',
      AMOUNT_OFF: 'Monto de descuento',
      OFF: 'de descuento'
    },
    DiscountItemTable: {
      Product: 'Producto',
      Discount: 'Descuento',
      MaxQuantity: 'Cantidad máxima por pedido',
      Optional: '(opcional)'
    }
  },
  SummaryPage: {
    Title: 'Resumen',
    Description: 'Revisa y envía.',
    SKUSettingsCardTable: {
      Tooltip: 'No hay puntos de contacto asociados.'
    },
    Alert:
      'Algunas SKU no se incluirán en esta oferta porque no están asociadas con puntos de contacto. Es posible que el número estimado de PDV objetivo cambie cuando la audiencia de Smart esté activada.',
    AlertButton: 'Mostrar SKU',
    SkusWithoutTouchpointsModal: {
      Title: 'SKU sin puntos de contacto',
      Subtitle:
        'Debe asociar estas SKU con puntos de contacto si desea incluirlas en esta oferta. De lo contrario, no se incluirán. Vaya a la página Detalles de puntos de contacto para asociarlas con un punto de contacto.',
      Paragraph:
        'Para agregar estas SKU a un punto de contacto, vaya a Detalles de puntos de contacto.',
      ConfirmMessage: 'Ir a Detalles del punto de contacto',
      CancelMessage: 'Cerrar'
    },
    PopModelAlert: {
      Failed:
        'No hay PDV estimados objetivo después de la activación de la audiencia inteligente. Revisa las SKU o elige otra audiencia.',
      Processed:
        'La cantidad de PDVs objetivo ha cambiado debido a la activación de la audiencia inteligente.',
      Processing:
        'La cantidad estimada de PDVs objetivo está en curso debido a la activación de la audiencia inteligente. Se actualizará automáticamente cuando haya terminado.'
    },
    SKUTable: {
      Title: 'SKU'
    },
    DiscountItemsStepsTable: {
      Title: 'Descuentos y cantidades',
      Tier: 'Nivel',
      MinQuantity: 'Cantidad mín. por pedido',
      MaxQuantity: 'Cantidad max. por pedido',
      FixDiscount: 'Descuento %'
    },
    DiscountTable: {
      title: 'Descuento',
      DiscountType: 'Tipo de descuento',
      Product: 'Productos',
      Discount: 'Porcentaje de descuento',
      MaxQuantity: 'Cantidad máxima por pedido',
      optional: '(opcional)'
    }
  },
  StepActions: {
    Next: 'Siguiente',
    Back: 'Volver',
    SaveAndExit: 'Guardar y salir',
    Publish: 'Publicar'
  },
  AudienceCard: {
    Title: 'Audiencia',
    EditButton: 'Editar',
    AudienceName: 'Nombre de la audiencia',
    EstimatedPOCsTargeted: 'POC objetivo estimados',
    Traits: 'Rasgos',
    ShowAll: 'Mostrar todo',
    MoreTraits: '{value} rasgos',
    OneTrait: '{value} rasgo',
    CSVUploaded: 'CSV uploaded'
  },
  DetailsPage: {
    Title: 'Detalles de la oferta',
    PublishedBy: 'Publicado por',
    PublishersEmail: 'Correo del editor',
    PublishedAt: 'Publicado en',
    publishedTime: 'en'
  },
  LeaveDialog: {
    Title: '¿Salir de la creación de oferta??',
    Description: 'Se perderán los cambios no guardados.',
    Cancel: 'No, volver',
    CancelOnSideBar: 'No, continuar con la creación',
    Leave: 'Sí, salir'
  },
  StatusCode: {
    DRAFT: 'Borrador',
    PREPARING_LEVER: 'En preparación',
    SCHEDULED: 'Agendado',
    ACTIVE: 'Activo',
    ENDED: 'Finalizado',
    ERROR: 'Error',
    PENDING_DELETION: 'Desactivación pendiente',
    DELETED: 'Desactivado'
  },
  SettingsCard: {
    Title: 'Configuración',
    EditButton: 'Editar',
    OfferName: 'Nombre de la oferta',
    OfferId: 'ID de oferta',
    Period: 'Período',
    PeriodSeparator: 'to',
    Status: 'Estado',
    Skus: 'SKUs'
  },
  TouchpointsVisibilityCard: {
    Touchpoints: 'Puntos de contacto',
    TouchpointDetails: 'Detalles del punto de contacto',
    ChallengeDateRange: 'Rango de fechas del desafío',
    interactiveCombo: 'Rango de fechas del punto de contacto',
    ChallengeDisplay: 'Pantalla de desafío',
    CollumGridtitle: 'Título',
    CampaignGroupId: 'Id. de grupo de campaña (opcional)',
    Description: 'Descripción',
    Allocation: 'Asignación',
    Points: 'Puntos',
    CoverImage: 'Imagen de portada',
    EditButton: 'Editar',
    RewardsCampaignId: 'ID del desafío de recompensas',
    Units: 'Unidades',
    OfferDateRange: 'Rango de fechas de la oferta',
    DiscountDateRange: 'Rango de fechas del punto de contacto',
    BudgetId: 'ID de presupuesto',
    Eligible: 'Máximo de pedidos por PDV',
    SkuLimit: 'Máximo de SKUs por PDV',
    DiscountAndQuantitiesFields: {
      Min: 'Cantidad mín. por pedido',
      Max: 'Cantidad máx. por pedido',
      Discount: 'Descuentos',
      MinDiscount: 'Descuento mín.',
      MaxDiscount: 'Descuento máx.'
    },
    TableDiscountAndQuantities: {
      Tier: 'Nivel',
      Min: 'Cantidad mín. por pedido',
      Max: 'Cantidad máx. por pedido',
      Discount: 'Descuentos %',
      Actions: 'Eliminar',
      EmptyMessage: 'Aún no se agregaron descuentos.',
      ButtonAddTier: 'Agregar nivel'
    }
  },
  // NEED COPY DECK
  Touchpoints: {
    Category: {
      POINTS_BASED: 'Ofertas basadas en puntos',
      POINTS_BASED_MULTIPLE_ORDER: 'Ofertas basadas en puntos',
      POINTS_BASED_VOLUME_FIXED: 'Ofertas basadas en puntos',
      PROMOTIONS: 'Promociones',
      PROMOTIONS_PERSONALIZED: 'Promociones personalizadas',
      PROMOTIONS_DISCOUNT: 'Promociones',
      PROMOTIONS_TIERED_DISCOUNT: 'Promociones',
      PROMOTIONS_INTERACTIVE_COMBOS: 'Promociones',
      INTERACTIVE_COMBO: 'Promociones',
      REWARDS_CHALLENGE: 'Ofertas basadas en puntos',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Ofertas basadas en puntos'
    },
    DiscountsAndQuantities: {
      MIN_QUANTITY: 'Cantidad mín. por pedido',
      QUANTITY_PLACEHOLDER: 'Enter a number',
      MAX_QUANTITY: 'Cantidad max. por pedido',
      DISCOUNT: 'Discount',
      DISCOUNT_PLACEHOLDER: 'Enter a discount percentage'
    },
    Type: {
      POINTS_BASED_MULTIPLE_ORDER: 'Pedido múltiple',
      SINGLE_PURCHASE: 'Single order',
      DISCOUNTS: 'Discount',
      PROMOTIONS_TIERED_DISCOUNT: 'Descuentos escalonados',
      STEPPED_DISCOUNTS: 'Stepped discount',
      FIXED_COMBOS: 'Fixed combos',
      POINTS_BASED_VOLUME_FIXED: 'Volumen fijo',
      SPEND_BASED: 'Spend based',
      VOLUME_FIXED: 'Volume fixed',
      PROMOTIONS_DISCOUNT: 'Descuentos',
      PROMOTIONS_DISCOUNT_QUANTITY: 'Descuentos y cantidades',
      REWARDS_CHALLENGE: 'Pedido múltiple',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Pedido múltiple',
      PROMOTIONS_INTERACTIVE_COMBOS: 'Interactive combo'
    },
    TouchpointsDescriptions: {
      POINTS_BASED_MULTIPLE_ORDER:
        'Una oferta que permite que los PDV ganen puntos al comprar los SKU requeridos en varios pedidos dentro de un plazo establecido.',
      SINGLE_PURCHASE:
        'Specific purchase quantity per SKU that can be purchased in one single order within the challenge period.',
      POINTS_BASED_VOLUME_FIXED:
        'Una oferta que permite a los PDV ganar puntos al comprar una cantidad requerida de SKU dentro de un plazo establecido.',
      SPEND_BASED:
        'Overall purchase value ($$), the user can of which SKU from the challenge SKU list, that can be purchased in multiple orders within the challenge period.',
      DISCOUNTS:
        'Minimum and Maximum purchase quantity per SKU, that every time the customers reaches that quantity in an order within the promotion period they will get the benefit, but only for a limited quantity.',
      PROMOTIONS_TIERED_DISCOUNT:
        'Los PDV que alcancen la cantidad requerida dentro del período de promoción recibirán un descuento que aumenta a medida que los PDV compran más productos, y cada paso ofrece un descuento más alto que el anterior.',
      STEPPED_DISCOUNTS:
        'Specific minimum purchase quantity per SKUs and specific benefit per range of purchase quantity, every time the customers reaches a specific range minimum purchase quantity the benefit will increase.',
      FIXED_COMBOS:
        'Specific minimum purchase quantity per SKU that can be purchased in one single order within the promotion period.',
      PROMOTIONS_DISCOUNT:
        'Los PDV que alcancen la cantidad requerida dentro del período de promoción recibirán un descuento, pero solo por una cantidad limitada.',
      PROMOTIONS_DISCOUNT_QUANTITY: 'The values below will be applied to all selected SKUs.',
      PROMOTIONS_INTERACTIVE_COMBOS:
        'Proporciona descuentos a las PDV que alcanzan la cantidad requerida en una combinación de productos.'
    }
  },
  Toast: {
    Success: 'Campaign created',
    Error: 'Something went wrong. Try again.'
  },
  SideBar: {
    AudienceBuilder: 'Generador de audiencias'
  },
  Countries: {
    BR: 'Brasil',
    MX: 'México',
    CO: 'Colombia',
    AR: 'Argentina',
    ZA: 'Sudáfrica'
  }
};

export default es419;
