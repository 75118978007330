import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../constants';
import { useBeesContext } from '../../context/BeesContext/BeesContext';
import useLever from '../../context/LeverContext/hooks/useLever/useLever';
import { LeverProvider } from '../../context/LeverContext/LeverContext';

function LeverProviderContainer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { userInfo } = useBeesContext();
  const { data } = useLever();

  useEffect(() => {
    if (data?.status && data?.status !== 'DRAFT') {
      navigate(`${BASE_URL}/details/${data?.id}`);
    }
  }, [data, navigate]);

  useEffect(() => {
    const supposedCountry = id?.slice(0, 2);
    const isValidCountry = userInfo.availableCountries.find(({ code }) => code === supposedCountry);

    if (id && !isValidCountry) {
      navigate(BASE_URL);
    }
  }, [id, navigate, userInfo]);

  return <Outlet />;
}

export default function LeverProviderWithContext() {
  return (
    <LeverProvider>
      <LeverProviderContainer />
    </LeverProvider>
  );
}
