import { AxiosError } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { LeverModel } from '../../../../domain/Lever';
import { useApiService } from '../../../../hooks/useApiService/useApiService';
import useGetLeverParams from '../../../../hooks/useGetLeverParams/useGetLeverParams';
import { defaultInitialValueEnvContext } from '../../../../provider/EnvProvider';
import { getById } from '../../LeverService';

export default function useGetLeverById(initialState: LeverModel) {
  const { id: leverIdParam } = useGetLeverParams();
  const [id, setId] = useState(leverIdParam);
  const { enabledEuropeanCountries } = defaultInitialValueEnvContext;
  const endpoint = useApiService(enabledEuropeanCountries);

  const { data, isFetching, isSuccess, error, refetch } = useQuery<LeverModel, AxiosError>(
    ['content', id],
    () => getById(id, endpoint),
    {
      staleTime: Infinity,
      retry: false,
      enabled: !!id,
      placeholderData: initialState
    }
  );

  return {
    data,
    error,
    isSuccess,
    refetch,
    isEditing: !!id,
    isLoading: isFetching,
    changeId: setId
  };
}
