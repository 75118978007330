import React from 'react';

export type VendorIdFromEnv = {
  BR: string;
  MX: string;
  CO: string;
  PE: string;
  EC: string;
};

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  optimizelyKey: string;
  segmentKey: '';
  useFullstory: boolean;
  enabledEuropeanCountries: string[];
};

export const defaultInitialValueEnvContext: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  optimizelyKey: '',
  segmentKey: '',
  useFullstory: false,
  enabledEuropeanCountries: []
};

export const EnvContext = React.createContext(defaultInitialValueEnvContext);

export const useEnvContext = () => React.useContext(EnvContext);

export function EnvProvider({ env, children }: { env: EnvConfig; children: React.ReactNode }) {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
}
