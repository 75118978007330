export const LEVERS_BUSINESS_SERVICE_BASE_URL = '/api/dlb-levers-business-service';
export const LEVERS_BUSINESS_SERVICE_BASE_EU_URL = '/api/eu/dlb-levers-business-service';
export const GET_SKU_CONFIG = `/sku-config`;
export const GET_TOUCHPOINTS_CONFIG = `/touchpoints`;
export const GET_TOUCHPOINT_CATEGORIES = `/touchpoints-categories`;
export const AUDIENCES_BUSINESS_SERVICE_BASE_URL =
  '/api/audience-management-audience-business-service/v1/custom-audience/audiences';
export const STRATEGIES_URL_STOPLIGHT_MOCK =
  'https://stoplight.io/mocks/bees/dlb-levers-documentation/197021075';
export const AUDIENCES_URL = '/audiences?origin=offer';
export const GET_COUNTRIES = `/countries`;
export const LEVER_SERVICE_BASE_URL = '/dlb-lever-management-service';

export const CMS_URL = {
  sit: 'https://cms-services-sit.bees-platform.dev/api/v1',
  uat: 'https://cms-services-uat.bees-platform.dev/api/v1',
  prod: 'https://cms-services.bees-platform.com/api/v1'
};

export const THEME_SPACE = {
  SPACE_8_PX: 2,
  SPACE_16_PX: 4,
  SPACE_24_PX: 6,
  SPACE_32_PX: 8,
  SPACE_40_PX: 10,
  SPACE_48_PX: 12
} as const;

export const STATUS_COLORS = {
  DRAFT: {
    text: '#B37724',
    indicator: '#FFAA33',
    backgroundColor: '#FFF7EB'
  },
  PREPARING_LEVER: {
    text: '#8C761C',
    indicator: '#8C761C',
    backgroundColor: '#FFF5CC'
  },
  SCHEDULED: {
    text: '#0B59AF',
    indicator: '#0F7FFA',
    backgroundColor: '#E7F2FF'
  },
  ACTIVE: {
    text: '#22843B',
    indicator: '#31BD54',
    backgroundColor: '#EAF8EE'
  },
  ENDED: {
    text: '#705AB3',
    indicator: '#A080FF',
    backgroundColor: '#F6F2FF'
  },
  ERROR: {
    text: '#A02A23',
    indicator: '#E53C32',
    backgroundColor: '#FCECEB'
  },
  PENDING_DELETION: {
    text: '#665339',
    indicator: '#665339',
    backgroundColor: '#DECDBA'
  },
  DELETED: {
    text: '#585858',
    indicator: '#585858',
    backgroundColor: '#DBDADA'
  },
  default: {
    text: '#999999',
    indicator: '#000000',
    backgroundColor: '#000000'
  }
};

export const LOCAL_STORAGE_KEYS = {
  userInfo: 'userInfo',
  selectedStrategy: 'selectedStrategy',
  selectedSubStrategy: 'selectedSubStrategy',
  selectedSeller: 'selectedSeller'
};

export const STEPS_NAME = {
  0: 'CAMPAIGN_SETTINGS',
  1: 'AUDIENCE_SEGMENTATION',
  2: 'TOUCHPOINTS',
  3: 'TOUCHPOINT_DETAILS',
  4: 'SUMMARY'
};

export const BASE_URL = '/offer-creation';
export const STEP_ROUTES = [
  '/settings',
  '/audience',
  '/touchpoints',
  '/touchpoint-details',
  '/summary'
];

export const INPUT_NAME_MAX_LENGTH = 50;
export const PAGE_SIZE_WITH_25 = 25;
export const PAGE_SIZE_WITH_50 = 50;
export const PAGE_SIZE_WITH_100 = 100;

export enum ProcessStatus {
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING'
}

export const OPTIMIZELY_PROJECT_NAME = 'BEES Engage';

export const LOCAL_STORAGE_COUNTRY = 'vendor';

export const DEFAULT_FORM_BASE_NAME = 'form';

export const DEBOUNCE_DELAY_SEGMENT = 1000;

export const DEBOUNCE_DELAY_DISCOUNT_FIELDS = 300;

export const PROFILE = 'offer';

export const CSV_ID_HEADER = 'SKU_ID';

export const MAX_LINES = 300;
