import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { PAGE_SIZE_WITH_25 } from '../../../../constants';
import { useBeesContext } from '../../../../context/BeesContext/BeesContext';
import { useApiService } from '../../../../hooks/useApiService/useApiService';
import { defaultInitialValueEnvContext } from '../../../../provider/EnvProvider';
import { getAll } from '../../LeverService';

export const useGetAllLevers = ({
  page = 0,
  pageSize = PAGE_SIZE_WITH_25,
  sort = 'DESC',
  sortField = 'period'
}) => {
  const {
    userInfo: { selectedVendor, isManufacturer }
  } = useBeesContext();

  const { enabledEuropeanCountries } = defaultInitialValueEnvContext;
  const endpoint = useApiService(enabledEuropeanCountries);

  const { data, isLoading, isError, error, refetch } = useQuery(
    ['getAllLevers', page, pageSize, sort, sortField],
    () => getAll({ page, pageSize, sort, sortField }, endpoint),
    {
      staleTime: 300000,
      cacheTime: 2500
    }
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (!isManufacturer) {
      refetch();
    }
  }, [selectedVendor, isManufacturer]);

  return {
    data,
    isLoading,
    isError,
    error,
    refetch
  };
};
