/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { BASE_URL, GET_TOUCHPOINTS_CONFIG, GET_TOUCHPOINT_CATEGORIES } from '../../constants';
import {
  GetAllLeversContentResponse,
  GetAllParams,
  ResponseStatus,
  ValidateNameParams
} from './LeverService.d';

import { Lever, LeverModel } from '../../domain/Lever';

const VALID_STATUS = [ResponseStatus.SUCCESS, ResponseStatus.NOT_MODIFIED];

async function validateName({ name, id }: ValidateNameParams, endpoint: string): Promise<boolean> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${endpoint}/levers-validate-name`,
      params: { name, id }
    });
    if ('response' in response && response.response?.status === ResponseStatus.ERROR) return false;
    if (VALID_STATUS.includes(response?.status)) return true;
    throw response;
  } catch (error) {
    log.error('validate-name-error', error);
    throw error;
  }
}

const getAll = async (
  { page, pageSize, sort, sortField }: GetAllParams,
  endpoint: string
): Promise<GetAllLeversContentResponse> => {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${endpoint}/levers`,
      params: { page, pageSize, sort, sortField }
    });
    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('get-all-levers-error', error);
    throw error;
  }
};

async function update(
  data: Lever,
  endpoint: string,
  callback?: (param: Lever) => void
): Promise<Lever> {
  const log = useLogService();
  try {
    const response = await Api.put({
      url: `${endpoint}/levers/${data.id}`,
      body: { ...data, id: '' }
    });
    if (response.hasError) throw response;

    if (callback) callback(response.data);
    return response.data;
  } catch (error) {
    log.error('update-lever', error);
    throw error;
  }
}

async function publish(data: Lever, endpoint: string): Promise<Lever> {
  const log = useLogService();
  try {
    const response = await Api.put({
      url: `${endpoint}/levers-publish/${data.id}`,
      body: { ...data, id: '' }
    });
    if (response.hasError) throw response;
    return response.data;
  } catch (error) {
    log.error('publish-lever', error);
    throw error;
  }
}

async function create(data: Lever, endpoint: string) {
  const log = useLogService();
  try {
    const response = await Api.post<Lever>({
      url: `${endpoint}/levers`,
      body: data
    });

    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('create-lever', error);
    throw error;
  }
}

async function getById(id: string, endpoint: string) {
  const log = useLogService();

  try {
    const response = await Api.get<LeverModel>({
      url: `${endpoint}/levers/${id}`
    });

    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('get-lever-by-id-error', error);
    if (error.response?.status === 404) {
      window.history.pushState(null, '', BASE_URL);
    }
    throw error;
  }
}

async function getTouchpointsConfig(
  {
    country,
    personalized,
    touchpoint,
    isMocked
  }: {
    touchpoint: string;
    personalized: boolean;
    country: string;
    isMocked?: boolean;
  },
  endpoint: string
): Promise<any> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${endpoint}${GET_TOUCHPOINTS_CONFIG}`,
      params: {
        touchpoint,
        personalized,
        country,
        isMocked
      }
    });

    if ('hasError' in response) {
      throw response;
    }

    return response.data;
  } catch (error) {
    log.error('get-touchpoints-config', error);
    throw error;
  }
}

async function getTouchpointCategories(
  {
    isMocked,
    personalized
  }: {
    isMocked?: boolean;
    personalized: boolean;
  },
  endpoint: string
): Promise<any> {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${endpoint}${GET_TOUCHPOINT_CATEGORIES}`,
      params: { isMocked, personalized }
    });

    if ('hasError' in response) {
      throw response;
    }

    return response.data;
  } catch (error) {
    log.error('get-touchpoint-categories', error);
    throw error;
  }
}

async function deleteLever(id: string, endpoint: string) {
  const log = useLogService();
  try {
    const response = await Api.delete({
      url: `${endpoint}/levers/${id}`
    });

    if ('hasError' in response) throw response;
    return response.data;
  } catch (error) {
    log.error('delete-lever', error);
    throw error;
  }
}

export {
  create,
  getAll,
  getById,
  getTouchpointCategories,
  getTouchpointsConfig,
  publish,
  update,
  validateName,
  deleteLever
};
