import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL, STEPS_NAME, STEP_ROUTES } from '../../constants';
import useLever from '../LeverContext/hooks/useLever/useLever';
import {
  IStepperActions,
  IStepperContext,
  IStepperContextData,
  UpdateActions
} from './StepperContext.d';

export const ROUTES = ['settings', 'audience', 'touchpoints', 'touchpoint-details', 'summary'];

const StepperContext = createContext({} as IStepperContext);

function reducer(state: any, action: UpdateActions): IStepperContextData {
  return { ...state, ...action.payload };
}

export const initialState: IStepperContextData = {
  currentStep: 0,
  actions: {
    next: {
      label: 'StepActions.Next',
      disabled: false
    },
    back: {
      label: 'StepActions.Back',
      disabled: false
    },
    saveAndExit: {
      label: 'StepActions.SaveAndExit',
      disabled: false
    },
    publish: {
      label: 'StepActions.Publish',
      disabled: false
    }
  }
};

function StepperProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: currentLever } = useLever();
  const navigate = useNavigate();
  const { setCurrentStep } = useLever();
  const updateCurrentStep = useCallback(
    (currentStep: number) => {
      dispatch({ payload: { currentStep } });
    },
    [dispatch]
  );

  useEffect(() => {
    const currentStep = STEP_ROUTES.findIndex((route) => location.pathname.includes(route));
    if (currentStep !== -1) {
      setCurrentStep(STEPS_NAME[currentStep]);
      updateCurrentStep(currentStep);
    } else {
      localStorage.setItem('csvFilesCount', '0');
    }
  }, [location.pathname, setCurrentStep, updateCurrentStep]);

  const updateActions = useCallback(
    (actions: IStepperActions) => {
      dispatch({
        payload: {
          actions: {
            next: {
              ...state.actions.next,
              ...actions.next
            },
            back: {
              ...state.actions.back,
              ...actions.back
            },
            saveAndExit: {
              ...state.actions.saveAndExit,
              ...actions.saveAndExit
            },
            publish: {
              ...state.actions.publish,
              ...actions.publish
            }
          }
        }
      });
    },
    [state.actions]
  );

  const idParam = useMemo(() => (currentLever?.id ? `/${currentLever.id}` : ''), [currentLever]);

  const nextStep = useCallback(() => {
    const currentUrl = location.pathname;
    const currentStep = ROUTES.findIndex((route) => location.pathname.includes(route));
    const newUrl = currentUrl.split(ROUTES[currentStep])[0] + ROUTES[currentStep + 1] + idParam;

    navigate(newUrl, {
      state: { currentStep: document.location.href }
    });
    dispatch({ payload: { currentStep: currentStep + 1 } });
    updateCurrentStep(currentStep + 1);
  }, [location.pathname, idParam, navigate, updateCurrentStep]);

  const previousStep = useCallback(() => {
    const currentStep = ROUTES.findIndex((route) => location.pathname.includes(route));
    if (currentStep > 0) {
      const currentUrl = location.pathname;

      const newUrl = currentUrl.split(ROUTES[currentStep])[0] + ROUTES[currentStep - 1] + idParam;

      navigate(newUrl, {
        state: { currentStep: document.location.href }
      });
      dispatch({ payload: { currentStep: currentStep - 1 } });
    }
  }, [location.pathname, idParam, navigate]);

  const goToHomePage = useCallback(() => {
    navigate(BASE_URL, {
      state: { currentStep: document.location.href }
    });
  }, [navigate]);

  const contextValue: IStepperContext = useMemo(() => {
    return {
      nextStep,
      previousStep,
      goToHomePage,
      updateActions,
      updateCurrentStep,
      actions: state.actions,
      currentStep: ROUTES.findIndex((route) => location.pathname?.includes(route))
    };
  }, [
    nextStep,
    previousStep,
    updateActions,
    updateCurrentStep,
    state.actions,
    location.pathname,
    goToHomePage
  ]);

  return <StepperContext.Provider value={contextValue}>{children}</StepperContext.Provider>;
}

function useStepperContext() {
  return useContext(StepperContext);
}

export { StepperContext, StepperProvider, useStepperContext };
