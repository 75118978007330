import {
  SKUCheckBoxInteraction,
  SKUClearSearch,
  SKUClearSelection,
  SKUReloadListClicked,
  SKUSearch,
  SKUSelectAllClicked,
  SKUSelectionConfirmation,
  SKUSelectionMenuExit,
  sKUCSVUpload,
  sKUCheckBoxInteraction,
  sKUClearSearch,
  sKUClearSelection,
  sKULoadError,
  sKUReloadListClicked,
  sKUSearch,
  sKUSelectAllClicked,
  sKUSelectionConfirmation,
  sKUSelectionMenuExit
} from '../../../services/segment/segment';

const CURRENT_URL = window.location.href;

export const sKUSelectionConfirmationEvent = (
  props: SKUSelectionConfirmation,
  screenName: string
) => {
  sKUSelectionConfirmation({
    ...props,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const sKUSelectionMenuExitEvent = (props: SKUSelectionMenuExit, screenName: string) => {
  sKUSelectionMenuExit({
    ...props,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const skuClearSearchEvent = (props: SKUClearSearch, screenName: string) => {
  sKUClearSearch({
    ...props,
    url: CURRENT_URL,
    screen_name: screenName
  });
};

export const sKUSelectionAllEvent = (props: SKUSelectAllClicked, screenName: string) => {
  sKUSelectAllClicked({
    ...props,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const sKUClearSelectionEvent = (props: SKUClearSelection, screenName: string) => {
  sKUClearSelection({
    ...props,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const sKUSearchEvent = (props: SKUSearch, screenName: string) =>
  sKUSearch({
    ...props,
    url: CURRENT_URL,
    screen_name: screenName
  });

export const sKUCheckboxInteractionEvent = (props: SKUCheckBoxInteraction, screenName: string) =>
  sKUCheckBoxInteraction({
    ...props,
    url: CURRENT_URL,
    screen_name: screenName
  });

export const sKUSelectionReloadListClickedEvent = (
  params: SKUReloadListClicked,
  screenName: string
) => {
  sKUReloadListClicked({
    ...params,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const sKULoadErrorEvent = (campaignId: string | null, screenName: string) => {
  sKULoadError({
    campaign_id: campaignId ?? null,
    referrer: CURRENT_URL,
    screen_name: screenName,
    url: CURRENT_URL
  });
};

export const skuCsvUploadEvent = ({
  campaignId,
  countryCode,
  csvFilesCount,
  touchpointType,
  vendorName
}: {
  campaignId: string;
  countryCode: string;
  csvFilesCount: number;
  touchpointType: string;
  vendorName: string;
}) => {
  sKUCSVUpload({
    campaign_id: campaignId,
    country: countryCode,
    csv_files_count: csvFilesCount,
    touchpoint_type: touchpointType,
    vendor_name: vendorName
  });
};
