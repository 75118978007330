import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useApiService } from '../../../../hooks/useApiService/useApiService';
import { defaultInitialValueEnvContext } from '../../../../provider/EnvProvider';
import { getCountries } from '../../CountriesService';

export default function useGetCountries() {
  const { enabledEuropeanCountries } = defaultInitialValueEnvContext;
  const endpoint = useApiService(enabledEuropeanCountries);

  const { data, isSuccess, error, isLoading } = useQuery<any[], AxiosError>(
    'getCountries',
    () => getCountries(endpoint),
    {
      staleTime: Infinity,
      retry: false
    }
  );

  return {
    response: data,
    error,
    isLoading,
    isSuccess
  };
}
