import { BASE_URL } from '../../../constants';
import {
  audienceCalculationCompleted,
  campaignCreationFinished,
  campaignSummaryPageViewed,
  goToTouchpointDetailsButtonClicked,
  offerPublicationStatus,
  showAllAudiencesButtonClicked,
  showSKUsButtonClicked,
  showSKUsModalViewed,
  stepEditionFromSummaryPage
} from '../../../services/segment/segment';
import { getScreenName } from '../utils';
import {
  audienceCalculationCompletedEventProps,
  campaignSummaryPageViewedEventProps,
  goToTouchpointDetailsButtonClickedEventProps,
  showSKUsButtonClickedEventProps,
  showSKUsModalViewedEventProps
} from './summaryPageEvents.d';

const CURRENT_URL = window.location.href;
const DOCUMENT_REFERRER = document.location.origin;

export const summaryStepEditionEvent = (
  buttonLabel: string,
  campaignId: string,
  destinationUrl: string
) => {
  stepEditionFromSummaryPage({
    button_label: buttonLabel,
    button_name: 'Edit Button',
    campaign_id: campaignId,
    screen_name: getScreenName('summary'),
    url: CURRENT_URL,
    destination_url: `${DOCUMENT_REFERRER}${destinationUrl}`
  });
};

export const campaignSummaryPageViewedEvent = ({
  campaignId,
  referrer,
  accountsRecalculationStatus,
  initialAccountsTargeted,
  isPortfolioOptimizationActive
}: campaignSummaryPageViewedEventProps) => {
  campaignSummaryPageViewed({
    url: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`,
    referrer: referrer || `${DOCUMENT_REFERRER}${BASE_URL}`,
    campaign_id: campaignId,
    screen_name: getScreenName('summary'),
    accounts_recalculation_status: accountsRecalculationStatus,
    initial_accounts_targeted: initialAccountsTargeted,
    is_portfolio_optimization_active: isPortfolioOptimizationActive
  });
};

export const showAllAudiencesButtonClickedEvent = ({
  buttonLabel,
  campaignId,
  audienceTraitsCount,
  screenName
}: {
  campaignId: string;
  buttonLabel: string;
  audienceTraitsCount: number;
  screenName: string;
}) => {
  showAllAudiencesButtonClicked({
    url: CURRENT_URL,
    campaign_id: campaignId,
    screen_name: screenName,
    button_label: buttonLabel,
    button_name: 'Show All Audiences Button',
    audience_traits_count: audienceTraitsCount
  });
};

export const campaignCreationFinishedEvent = ({
  audienceTraitsCount,
  buttonLabel,
  buttonName,
  campaignId,
  country,
  endDate,
  executionType,
  initialAccountsTargeted,
  isPortfolioOptimizationActive,
  mechanismCount,
  mechanismTypes,
  recalculatedAccountsTargeted,
  skuListCount,
  startDate,
  strategyName,
  strategyLabel,
  subStrategyName,
  subStrategyLabel,
  touchpointType
}) => {
  campaignCreationFinished({
    country,
    audience_traits_count: audienceTraitsCount,
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    destination_url: `${BASE_URL}`,
    end_date: endDate,
    execution_type: executionType,
    initial_accounts_targeted: initialAccountsTargeted,
    is_portfolio_optimization_active: isPortfolioOptimizationActive,
    mechanism_count: mechanismCount,
    mechanism_types: mechanismTypes,
    recalculated_accounts_targeted: recalculatedAccountsTargeted,
    referrer: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`,
    sku_list_count: skuListCount,
    screen_name: getScreenName('summary'),
    start_date: startDate,
    strategy_name: strategyName,
    strategy_label: strategyLabel,
    sub_strategy_name: subStrategyName,
    sub_strategy_label: subStrategyLabel,
    touchpoint_type: touchpointType,
    url: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`
  });
};

export const offerPublicationStatusEvent = ({ campaignId, publishStatus }) => {
  offerPublicationStatus({
    campaign_id: campaignId,
    destination_url: `${BASE_URL}`,
    publish_status: publishStatus,
    referrer: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`,
    screen_name: getScreenName('summary'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`
  });
};

export const showSKUsButtonClickedEvent = ({
  campaignId,
  buttonLabel,
  skusWithoutTouchpointsCount
}: showSKUsButtonClickedEventProps) => {
  showSKUsButtonClicked({
    button_label: buttonLabel,
    button_name: 'Show SKUs',
    campaign_id: campaignId,
    skus_without_touchpoints_count: skusWithoutTouchpointsCount,
    screen_name: getScreenName('summary'),
    url: CURRENT_URL
  });
};

export const goToTouchpointDetailsButtonClickedEvent = ({
  buttonLabel,
  buttonName,
  skusWithoutTouchpointsCount,
  campaignId
}: goToTouchpointDetailsButtonClickedEventProps) => {
  goToTouchpointDetailsButtonClicked({
    button_label: buttonLabel,
    button_name: buttonName,
    campaign_id: campaignId,
    skus_without_touchpoints_count: skusWithoutTouchpointsCount,
    screen_name: getScreenName('summary'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`
  });
};

// This function is not being used in the codebase - need too see with product team if we can remove it
/* istanbul ignore next */
export const showSKUsModalViewedEvent = ({
  modalName,
  skusWithoutTouchpointsCount,
  campaignId
}: showSKUsModalViewedEventProps) => {
  showSKUsModalViewed({
    button_label: undefined, // remove this prop from the event
    button_name: undefined, // remove this prop from the event
    campaign_id: campaignId,
    modal_name: modalName,
    skus_without_touchpoints_count: skusWithoutTouchpointsCount,
    screen_name: getScreenName('summary'),
    url: `${DOCUMENT_REFERRER}${BASE_URL}/summary/${campaignId}`
  });
};

export const audienceCalculationCompletedEvent = ({
  initialAccountsTargeted,
  recalculatedAccountsTargeted,
  campaignId,
  touchpointTypes,
  strategyLabel,
  strategyName,
  audienceId,
  audienceName,
  SKUList
}: audienceCalculationCompletedEventProps) => {
  audienceCalculationCompleted({
    initial_accounts_targeted: initialAccountsTargeted,
    recalculated_accounts_targeted: recalculatedAccountsTargeted,
    campaign_id: campaignId,
    touchpoint_types: touchpointTypes,
    strategy_label: strategyLabel,
    strategy_name: strategyName,
    audience_id: audienceId,
    audience_name: audienceName,
    screen_name: getScreenName('summary'),
    sku_list: SKUList
  });
};
