import { Button, Dialog, Heading } from '@hexa-ui/components';
import { useState } from 'react';
import { onCloseProps, useGenericDialogProps } from './useGenericDialog.d';
import Styled from './useGenericDialog.styles';

const useGenericDialog = (onClose?: onCloseProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState<useGenericDialogProps>(
    {} as useGenericDialogProps
  );

  const onOpenDialog = (loadedData: useGenericDialogProps) => {
    setIsOpen(true);
    setDialogProps(loadedData);
  };

  const onCloseDialog = (
    triggerSegment = false,
    buttonLabel = 'X',
    buttonName = 'X',
    modalName = ''
  ) => {
    setIsOpen(false);
    if (triggerSegment) {
      onClose?.(buttonLabel, buttonName, modalName);
    }
  };

  const handleCancelClick = () => {
    if (dialogProps.onCancel) {
      dialogProps.onCancel();
    } else {
      onCloseDialog(true, dialogProps.cancelMessage, 'Cancel', dialogProps.modalName);
    }
  };

  const handleConfirmClick = () => {
    if (dialogProps.onConfirm) {
      dialogProps.onConfirm();
    }

    setIsOpen(false);
  };

  const renderActions = () => {
    if (dialogProps.removeConfirmButton && dialogProps.removeCancelButton) {
      return null;
    }

    return (
      <Styled.WrapperDialogButtons>
        <Styled.ButtonsSide reverse={dialogProps.reverse ? 'true' : 'false'}>
          {!dialogProps.removeConfirmButton && (
            <Dialog.Close>
              <Button
                data-testid="button-dialog-confirm"
                isLoading={isLoading}
                disabled={isLoading}
                variant={dialogProps.buttonConfirmColor ?? 'primary'}
                onClick={handleConfirmClick}
              >
                {dialogProps.confirmMessage}
              </Button>
            </Dialog.Close>
          )}
          {!dialogProps.removeCancelButton && (
            <Dialog.Close>
              <Button
                data-testid="button-dialog-cancel"
                disabled={isLoading}
                variant={dialogProps.buttonCancelColor ?? 'secondary'}
                onClick={handleCancelClick}
              >
                {dialogProps.cancelMessage}
              </Button>
            </Dialog.Close>
          )}
        </Styled.ButtonsSide>
      </Styled.WrapperDialogButtons>
    );
  };

  return {
    onOpenDialog,
    onCloseDialog,
    setIsLoading,
    Dialog: () =>
      isOpen ? (
        <Dialog.Root
          contentCss={{
            overflowY: 'hidden',
            maxWidth: dialogProps.maxWidth ?? '100%'
          }}
          data-testid="dialog"
          title={<Heading size="H2">{dialogProps.title}</Heading>}
          headerHeight="auto"
          onClose={
            dialogProps.onCancel || (() => onCloseDialog(true, 'X', 'X', dialogProps.modalName))
          }
          open={isOpen}
          closeButton={!dialogProps.removeCloseButton}
          actions={renderActions()}
        >
          {dialogProps.body}
        </Dialog.Root>
      ) : null
  };
};

export default useGenericDialog;
