/* eslint-disable react-hooks/rules-of-hooks */
import { useLogService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { GET_COUNTRIES } from '../../constants';

export const getCountries = async (endpoint: string) => {
  const log = useLogService();

  try {
    const response = await Api.get({ url: `${endpoint}${GET_COUNTRIES}` });

    if ('hasError' in response) throw response;

    return response.data;
  } catch (error) {
    log.error('get-countries - ', error);

    throw error;
  }
};
