import {
  audienceCreationStartedEvent,
  audienceSelectedEvent,
  audienceSelectionPageViewedEvent
} from './audiencePageEvents/audiencePageEvents';

import {
  coverImageModificationTriggeredEvent,
  dateRangeSelectedEvent,
  discountValueUpdatedEvent,
  executionTypeSelectedEvent,
  sKUMaximumQuantityUpdatedEvent,
  sKUMinimumQuantityUpdatedEvent,
  sKUQuantityUpdatedEvent,
  sKURemovedEvent,
  sKUSelectionTriggeredEvent,
  tierActionPerformedEvent,
  touchpointDetailsPageViewedEvent
} from './touchpointsDetailsPageEvents/touchpointsDetailsPageEvents';

import {
  campaignListingPageViewedEvent,
  listingActionPerformedEvent,
  listingCreateNewCampaignClickedEvent,
  listingItemsPerPageChangeEvent,
  listingSortInteractionEvent
} from './campaignListingPageEvents/campaignListingPageEvents';
import {
  campaignStrategyPageViewedEvent,
  strategySelectedEvent,
  subStrategySelectedEvent
} from './campaignsPageEvents/campaignsPageEvents';
import {
  audienceTraitsModalViewedEvent,
  forwardStepperNavigationEvent,
  saveAndExitClickedEvent
} from './generalEvents/generalEvents';
import {
  campaignSettingsAddSkuClickedEvent,
  campaignSettingsPageViewedEvent,
  campaignSettingsPeriodSelectedEvent,
  campaignSettingsRemoveSKUEvent,
  settingsNameProvidedEvent
} from './settingsPageEvents/settingsPageEvents';
import {
  sKUCheckboxInteractionEvent,
  sKUClearSelectionEvent,
  sKULoadErrorEvent,
  sKUSearchEvent,
  sKUSelectionAllEvent,
  sKUSelectionConfirmationEvent,
  sKUSelectionMenuExitEvent,
  sKUSelectionReloadListClickedEvent,
  skuClearSearchEvent,
  skuCsvUploadEvent
} from './skusSelectorEvents/skusSelectorEvents';

import {
  mechanismTypeCheckBoxInteractionEvent,
  selectTouchpointButtonClickedEvent,
  toggleInteractionEvent,
  touchpointCategoryInteractionEvent,
  touchpointDeletedEvent,
  touchpointSelectedEvent,
  touchpointSelectionPageViewedEvent,
  touchpointsSearchEvent
} from './touchpointsPageEvents/touchpointsPageEvents';

import { campaignDetailsPageViewedEvent } from './detailsPageEvents/detailsPageEvents';
import {
  audienceCalculationCompletedEvent,
  campaignCreationFinishedEvent,
  campaignSummaryPageViewedEvent,
  goToTouchpointDetailsButtonClickedEvent,
  offerPublicationStatusEvent,
  showAllAudiencesButtonClickedEvent,
  showSKUsButtonClickedEvent,
  summaryStepEditionEvent
} from './summaryPageEvents/summaryPageEvents';

import {
  campaignCreationFlowAbandonmentEvent,
  modalCancelOrExitEvent,
  deleteOfferEvent
} from './modalEvents/modalEvents';

const useSegment = () => {
  return {
    audienceSelectedEvent,
    audienceSelectionPageViewedEvent,
    audienceCreationStartedEvent,
    campaignStrategyPageViewedEvent,
    strategySelectedEvent,
    subStrategySelectedEvent,
    campaignSettingsPageViewedEvent,
    forwardStepperNavigationEvent,
    settingsNameProvidedEvent,
    saveAndExitClickedEvent,
    campaignSettingsPeriodSelectedEvent,
    campaignSettingsAddSkuClickedEvent,
    sKUSearchEvent,
    campaignListingPageViewedEvent,
    listingItemsPerPageChangeEvent,
    sKUSelectionConfirmationEvent,
    skuCsvUploadEvent,
    sKUSelectionMenuExitEvent,
    skuClearSearchEvent,
    sKUCheckboxInteractionEvent,
    listingSortInteractionEvent,
    listingCreateNewCampaignClickedEvent,
    sKUClearSelectionEvent,
    sKUSelectionAllEvent,
    sKUSelectionReloadListClickedEvent,
    sKULoadErrorEvent,
    campaignSummaryPageViewedEvent,
    audienceTraitsModalViewedEvent,
    showAllAudiencesButtonClickedEvent,
    listingActionPerformedEvent,
    summaryStepEditionEvent,
    campaignDetailsPageViewedEvent,
    campaignCreationFlowAbandonmentEvent,
    modalCancelOrExitEvent,
    campaignSettingsRemoveSKUEvent,
    offerPublicationStatusEvent,
    showSKUsButtonClickedEvent,
    goToTouchpointDetailsButtonClickedEvent,
    audienceCalculationCompletedEvent,
    campaignCreationFinishedEvent,
    touchpointSelectionPageViewedEvent,
    selectTouchpointButtonClickedEvent,
    touchpointSelectedEvent,
    touchpointsSearchEvent,
    touchpointCategoryInteractionEvent,
    mechanismTypeCheckBoxInteractionEvent,
    touchpointDeletedEvent,
    sKUSelectionTriggeredEvent,
    touchpointDetailsPageViewedEvent,
    executionTypeSelectedEvent,
    sKUQuantityUpdatedEvent,
    dateRangeSelectedEvent,
    coverImageModificationTriggeredEvent,
    sKURemovedEvent,
    sKUMinimumQuantityUpdatedEvent,
    sKUMaximumQuantityUpdatedEvent,
    discountValueUpdatedEvent,
    toggleInteractionEvent,
    tierActionPerformedEvent,
    deleteOfferEvent
  };
};

export default useSegment;
