import {
  LEVERS_BUSINESS_SERVICE_BASE_EU_URL,
  LEVERS_BUSINESS_SERVICE_BASE_URL
} from '../../constants';
import { useBeesContext } from '../../context/BeesContext/BeesContext';

export function useApiService(enabledEuropeanCountries: string[]) {
  const { userInfo } = useBeesContext();

  const countryCode = userInfo.selectedCountry;

  const endpoint = enabledEuropeanCountries.includes(countryCode)
    ? LEVERS_BUSINESS_SERVICE_BASE_EU_URL
    : LEVERS_BUSINESS_SERVICE_BASE_URL;

  return endpoint;
}
