import { useLogService } from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import { GetSellersById } from './sellersService.d';

export const getSellersById = async (
  { manufacturerId, headers }: GetSellersById,
  endpoint: string
) => {
  const log = useLogService();

  try {
    const response = await Api.get({
      url: `${endpoint}/sellers/${manufacturerId}`,
      headers
    });

    if ('hasError' in response) throw response;

    return response.data;
  } catch (error) {
    log.error('get-seller-by-manufacturerId - ', error);
    throw error;
  }
};
